import axios from 'axios'

export const setupGlobalDefaults = () => {
  axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
  axios.defaults.xsrfCookieName = 'csrftoken'
}

const autoRedirectInterceptor = (response) => {
  const { data } = response
  if (data.meta && data.meta.redirect) {
    window.location.href = data.meta.redirect
  }
  return response
}

export const withNoFailure = axios.create({
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'csrftoken',
  // our order APIs assume ANY response should be processed in the success handler
  validateStatus: () => true,
})

withNoFailure.interceptors.response.use(autoRedirectInterceptor)

export const withCsrf = axios.create({
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'csrftoken',
})

withCsrf.interceptors.response.use(autoRedirectInterceptor)

export default {
  withNoFailure,
  withCsrf,
}

/* eslint-disable semi, indent */
/* eslint-disable global-require */
/* eslint no-param-reassign: 0 */
/**
 * App & View Module Loader
 * Idea from Paul Irish - http://paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/
 *
 * This script is executed client-side on all of our pages to load our javascript assets. It checks the class and ID
 * on the <body> tag and then looks for corresponding handlers in loaderChain object (see below).
 *
 * This module is tightly coupled with the styles Django app - notably:
 *      VIEW_STYLES_MAP from settings.py
 *      get_view_labels from utils.py
 *      styles_classes from context_processors.py
 *
 * Order of execution:
 *     loaderChain.base.init();
 *     loaderChain.appname.init();
 *     loaderChain.appname.viewname();
 *     loaderChain.baseLast();
 *
 * Load a module on a specific view by creating a handler at loaderChain.appname.viewname() that require()s the module.
 * Load a module on a specific app by creating the handler at loaderChain.appname.init()
 *
 */
import { setupGlobalDefaults } from 'axios_loader'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

// Why are we exporting an IIFE with no return value?
// Shouldn't we just execute this code and not export it?
const loader = ((k3) => {
  setupGlobalDefaults()

  const commonLoader = {
    reviewLoader: () => {
      const container = document.getElementById('main-content')
      const props = k3.bootstrapData

      import('providers/ReviewApp').then(({ default: App }) => {
        ReactDOM.hydrate(
          <AppContainer>
            <App {...props} />
          </AppContainer>,
          container
        )
        if (module.hot) {
          module.hot.accept('providers/ReviewApp', () => {
            import('providers/ReviewApp').then(({ default: NextApp }) => {
              ReactDOM.render(
                <AppContainer>
                  <NextApp {...props} />
                </AppContainer>,
                container
              )
            })
          })
        }
      })
    },
  }
  // TODO: replace all the repeated junk that follows with require.context cleverness
  const loaderChain = {
    base: {
      init: () => {
        import(/* webpackChunkName: "base" */ 'base/base').then(({ default: base }) => {
          k3.base = base
        })
      },
    },

    baseLast: {
      init: () => {
        import(/* webpackChunkName: "baseLast" */ 'base/baseLast').then(({ default: baseLast }) => {
          k3.baseLast = baseLast
        })
      },
    },

    landings_app: {
      init: () => {
        k3.landings = {}
      },

      home_page_view: () => {
        const props = {
          ...k3.bootstrapHeader,
          ...k3.bootstrapData,
        }

        const container = document.getElementById('main-content')
        import('providers/HomepageApp').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('providers/HomepageApp', () => {
              import('providers/HomepageApp').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },
    },

    ksearch_app: {
      init: () => {
        k3.products = {}
      },

      search_list_view: () => {
        const container = document.getElementById('main-content')
        const props = k3.bootstrapData
        import('providers/ProductListApp').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('providers/ProductListApp', () => {
              import('providers/ProductListApp').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },
    },

    longtailux_app: {
      init: () => {
        k3.products = {}
      },

      search_list_view: () => {
        const container = document.getElementById('main-content')
        const props = k3.bootstrapData
        import('providers/ProductListApp').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('providers/ProductListApp', () => {
              import('providers/ProductListApp').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },
    },

    scavenger_hunt_app: {
      init: () => {
        k3.products = {}
      },

      scavenger_hunt_view: () => {
        const container = document.getElementById('main-content')
        const props = k3.bootstrapData
        import('containers/ScavengerHuntContainer').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('containers/ScavengerHuntContainer', () => {
              import('containers/ScavengerHuntContainer').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },
    },

    k3_products_app: {
      init: () => {
        k3.products = {}
      },

      product_detail_view: () => {
        const container = document.getElementById('main-content')
        // Fallback to header in case of 404s on this route, as we rely on store props
        const props = k3.bootstrapData || k3.bootstrapHeader
        import('providers/ProductApp').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('providers/ProductApp', () => {
              import('providers/ProductApp').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },

      product_review_view: commonLoader.reviewLoader,
      brand_review_view: commonLoader.reviewLoader,
      category_review_view: commonLoader.reviewLoader,

      brand_list_view: () => {
        const container = document.getElementById('main-content')
        const props = k3.bootstrapData
        import('containers/BrandsIndexContainer').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('containers/BrandsIndexContainer', () => {
              import('containers/BrandsIndexContainer').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },
    },

    k3_orders_app: {
      init: () => {
        k3.order = k3.order || {}
        k3.checkout = k3.checkout || {}
      },

      // same as above
      order_modal_checkout_view: () => {
        // Dont mount on the 404 page
        if (!k3.bootstrapData) return
        const container = document.getElementById('page-content')
        const props = k3.bootstrapData

        import('containers/Checkout/CheckoutApp').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('containers/Checkout/CheckoutApp', () => {
              import('containers/Checkout/CheckoutApp').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },

      order_confirm_view: () => {
        const container = document.getElementById('page-content')
        const props = k3.bootstrapData
        import(
          /* webpackChunkName: "OrderConfirmContainer" */ 'containers/OrderConfirmContainer'
        ).then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
        })
        if (module.hot) {
          module.hot.accept('containers/OrderConfirmContainer', () => {
            import(
              /* webpackChunkName: "OrderConfirmContainer" */ 'containers/OrderConfirmContainer'
            ).then(({ default: NextApp }) => {
              ReactDOM.render(
                <AppContainer>
                  <NextApp {...props} />
                </AppContainer>,
                container
              )
            })
          })
        }
      },
    },

    k3_cart_app: {
      init: () => {
        k3.cart = k3.cart || {}
      },

      cart_detail_view: () => {
        const props = k3.bootstrapData
        Promise.all([
          import(/* webpackChunkName: "CartApp" */ 'providers/CartApp'),
          import(/* webpackChunkName: "cartDetail" */ 'apps/cart/cartDetail'),
        ]).then(([{ default: App }, { requireModalCheckout, continueToOrder }]) => {
          ReactDOM.render(
            <AppContainer>
              <App continueToOrder={continueToOrder} {...props} />
            </AppContainer>,
            document.getElementById('main-content')
          )
          if (typeof window !== 'undefined') {
            window.setTimeout(requireModalCheckout, 500)
          }
        })
        if (module.hot) {
          module.hot.accept('providers/CartApp', () => {
            Promise.all([
              import(/* webpackChunkName: "CartApp" */ 'providers/CartApp'),
              import(/* webpackChunkName: "cartDetail" */ 'apps/cart/cartDetail'),
            ]).then(([{ default: NextApp }, { continueToOrder }]) => {
              ReactDOM.render(
                <AppContainer>
                  <NextApp continueToOrder={continueToOrder} {...props} />
                </AppContainer>,
                document.getElementById('main-content')
              )
            })
          })
        }
      },
    },

    k3_customers_app: {
      init: () => {
        k3.customer = k3.customer || {}
      },
      react_dashboard_view: () => {
        const container = document.getElementById('main-content')
        const props = k3.bootstrapData

        import('providers/DashboardApp').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
          if (module.hot) {
            module.hot.accept('providers/DashboardApp', () => {
              import('providers/DashboardApp').then(({ default: NextApp }) => {
                ReactDOM.render(
                  <AppContainer>
                    <NextApp {...props} />
                  </AppContainer>,
                  container
                )
              })
            })
          }
        })
      },
    },

    k3_influencers_app: {
      influencer_shop_view: () => {
        const container = document.getElementById('main-content')
        const props = k3.bootstrapData
        import('providers/InfluencerShop').then(({ default: App }) => {
          ReactDOM.hydrate(
            <AppContainer>
              <App {...props} />
            </AppContainer>,
            container
          )
        })
      },
    },
  }

  // Checks loaderChain for a namespace and function, calls them.
  const exec = (func, funcname, args) => {
    funcname = funcname === undefined ? 'init' : funcname
    if (func !== '' && loaderChain[func] && typeof loaderChain[func][funcname] === 'function') {
      loaderChain[func][funcname](args)
    }
  }

  // Load events
  // Read '/static/js-build/' from template
  __webpack_public_path__ = JS_STATIC_URL // eslint-disable-line
  const bodyClasses = document.body.className.split(/\s+/)
  const bodyId = document.body.id
  exec('base')
  bodyClasses.forEach((bodyClass) => {
    exec(bodyClass)
    exec(bodyClass, bodyId)
  })
  exec('baseLast')
})(window.k3 || {})

export default loader

/* Update .eslintrc "polyfills" config as well for ESLint */

import 'core-js/features/number'
import 'core-js/features/symbol'
import 'core-js/features/promise'
import 'core-js/features/object'
import 'core-js/features/array'
import 'core-js/features/map'
import 'core-js/features/string'
import 'core-js/web/dom-collections'
import 'core-js/web/url-search-params'
import 'core-js/web/url'

if (!Math.trunc) {
  // IE 11 doesn't support Math.trunc
  Math.trunc = function (v) {
    return v < 0 ? Math.ceil(v) : Math.floor(v)
  }
}
